/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-04 15:49:22
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-08 14:49:09
 * @FilePath: \ysz_web_international\src\api\common\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request.js'

//获取职称列表
export function getProfessionList (query) {
  return request.post('/user/getProfessionList', query)
}

//国家/地区列表
export function getCountryList (query) {
  return request.post('/user/country/list', query)
}

//获取用户信息
export function getUserInfo (query) {
  return request.post('/user/getUserInfo', query)
}