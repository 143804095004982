if (!location.href.includes('https')) {
  location.href = location.href.replace('http', 'https')
}
// 获取屏幕宽度
let htmlWidth =
  document.documentElement.clientWidth || document.body.clientWidth;
const DeviceType = htmlWidth < 750 ? "mobile" : "pc"; // 判断是移动设备还是PC设备
const ProjectType = location.pathname === "/mobile/" ? "mobile" : "pc"; //// 判断是移动端项目还是PC端项目
console.log("DeviceType", DeviceType);
console.log("ProjectType", ProjectType);
const urlHash = [
  ["#/", "#/oriental_express"], //首页
  ["#/articles?contentType=1", "#/latestnews?module=latestNews"], //news
  [
    "#/articles?contentType=4",
    "#/clinicalPulse/list?moduleTitle=&module=leading",
  ], //chinese_doctor
  [
    "#/articles?contentType=3",
    "#/clinicalPulse/list?moduleTitle=MedTalk&module=medtalk",
  ], //MedTalk
  ["#/articles?contentType=2", "#/people?module=people"], //Entrepreneur
  ["#/articles?contentType=5", "#/partnership/list?module=platform"], //Partner Platform
  ["#/account", "#/user_center?type=1"],
  ["#/collection", "#/user_center?type=2"],
];
if (DeviceType === "mobile" && ProjectType === "pc") {
  if (location.hash.includes("#/articleDetails?id=")) {
    location.href =
      location.origin.replace("medj.cn", "medj.cn/mobile") +
      "/" +
      location.hash.replace("articleDetails", "article");
  } else {
    const index = urlHash.findIndex((item) => {
      return item[1] === location.hash;
    });
    console.log(index,'===000===');
    
    if (index !== -1) {
      console.log('==102==');
      location.href =
        location.origin.replace("medj.cn", "medj.cn/mobile") +
        "/" +
        urlHash[index][0];
    } else {
      console.log('==101==');
      
      location.href =
        location.origin.replace("medj.cn", "medj.cn/mobile") +
        "/" +
        location.hash;
    }
  }
}
if (DeviceType === "pc" && ProjectType === "mobile") {
  if (location.hash.includes("#/article?id=")) {
    location.href =
      location.origin.replace("medj.cn/mobile", "medj.cn") +
      "/" +
      location.hash.replace("article", "articleDetails");
  } else {
    const index = urlHash.findIndex((item) => {
      return item[0] === location.hash;
    });
    if (index !== -1) {
      location.href =
        location.origin.replace("medj.cn/mobile", "medj.cn") +
        "/" +
        urlHash[index][1];
    } else {
      location.href =
        location.origin.replace("medj.cn/mobile", "medj.cn") +
        "/" +
        location.hash;
    }
  }
}
