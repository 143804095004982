/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-05 16:37:48
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-12 09:40:39
 * @FilePath: \ysz_web_international\src\store\modules\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import storage from 'store'
import store from '@/store'
import { getUserInfo } from '@/api/common'
import router from '@/router'
const user = {
  state: {
    token: '',
    userInfo: {},
    isLogin: false,//是否登录
    temporaryUserInfo: {},//注册临时userInfo
    fogotInfo:{}//忘记密码临时信息
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_TEMPORARY_USER_INFO: (state, userInfo) => {
      state.temporaryUserInfo = userInfo
    },
    SET_IS_LOGIN: (state, isLogin) => {
      state.isLogin = isLogin
    },
    SET_FOGOT_INFO: (state, info) => {
      state.fogotInfo = info
    }
  },

  actions: {
    // 设置邮箱
     setfogotInfo ({ commit }, info) {
      commit('SET_FOGOT_INFO', info)
    },
    // 是否登录
    setIsLogin ({ commit },flag) {
      commit('SET_IS_LOGIN', flag)
    },
    //设置注册临时userInfo
    setTemporaryUserInfo ({ commit }, userInfo) {
      commit('SET_TEMPORARY_USER_INFO', userInfo)
    },
    //设置用户信息
    setUserInfo ({ commit }, userInfo) {
      commit('SET_USER_INFO', userInfo)
    },
    // 获取用户信息
    GetInfo ({ commit },flag) {
      return new Promise((resolve, reject) => {
        getUserInfo().then(response => {
          console.log('用户信息', response)
          commit('SET_USER_INFO', response.body)
          localStorage.setItem('userInfo', JSON.stringify(response.body))
          if (!flag) {//是否跳转到上一页 默认跳转
            router.replace(localStorage.getItem('oldPath'))
          }
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //退出登录
     Logout ({ commit }) {
      commit('SET_TOKEN', '')
      commit('SET_USER_INFO', {})
      localStorage.removeItem('userInfo')
      localStorage.removeItem('ysz_web_international_token')
      router.replace('/')
    }
  }
}

export default user