/*
 * @Author: hgc
 * @Date: 2024-07-10 18:19:04
 * @LastEditTime: 2024-07-10 18:20:56
 * @LastEditors: hgc
 * @Description: 
 * @FilePath: \ysz_web_international\src\utils\directive.js
 */

import Vue from "vue"
const imgUrl = require('../assets/images/img_null.png')

// 图片报错统一处理
// 在 Vue 项目中的 main.js 或类似的入口文件中
Vue.directive("img-error", {
  inserted(el, binding) {
    el.onerror = function () {
      if (binding.value) {
        // 使用默认图片
        el.src = binding.value
      } else {
        // 使用自定义默认图片或者清除错误图片
        el.src = imgUrl
        document.documentElement.scrollTop = 0
      }

      // 防止进入错误回调循环
      el.onerror = null
    }
  },
})
