/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-05 16:37:48
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-13 16:19:50
 * @FilePath: \ysz_web_international\src\store\modules\user.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import storage from 'store'
import store from '@/store'
import { getUserInfo } from '@/api/common'
import router from '@/router'
const base = {
  state: {
    anchorName: {},
    activeNavName:{}
  },

  mutations: {
    SET_ANCHOR_NAME: (state, anchor) => {
      state.anchorName = anchor
    },
    SET_ACTIVE_NAV_NAME:(state, name) => {
      console.log(name,'44444444name')
      state.activeNavName = name
    }
  },

  actions: {
    // 设置锚点名称
     setAcchorName ({ commit }, anchor) {
      commit('SET_ANCHOR_NAME', anchor)
    },
    //导航高亮
    setActiveNavName({ commit }, anchor) {
      console.log(anchor,44444444)
      commit('SET_ACTIVE_NAV_NAME', anchor)
    }
  }
}

export default base