
/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-05-27 14:13:42
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-08 16:01:15
 * @FilePath: \ysz_web_international\plugins\element-ui.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// element-ui.js
import Vue from 'vue'
import {
 Container,
 Header,
 Aside,
 Main,
 Menu,
 MenuItem,
 Button,
 Form,
 FormItem,
 Input,
 Select,
 Option,
 CarouselItem,
 Carousel,
 Image,
 Pagination,
 Popover,
 Checkbox,
 Descriptions,
 DescriptionsItem
} from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
 
const components = [
 Container,
 Header,
 Aside,
 Main,
 Menu,
 MenuItem,
 Button,
 Form,
 FormItem,
 Input,
 Select,
 Option,
 Carousel,
 CarouselItem,
 Image,
 Pagination,
 Popover,
 Checkbox,
 Descriptions,
 DescriptionsItem
];
 
const Element = {
 install (Vue) {
 components.forEach(component => {
  Vue.component(component.name, component)
 })
 }
}
 
Vue.use(Element, { locale })