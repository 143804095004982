/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-05 16:36:38
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-13 16:20:07
 * @FilePath: \ysz_web_international\src\store\getters.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const getters = {
  token: state => state.user.token,
  temporaryUserInfo: state =>state.user.temporaryUserInfo,
  isLogin: state => state.user.isLogin,
  fogotInfo: state => state.user.fogotInfo,
  anchorName: state => state.base.anchorName,
  userInfo: state => state.user.userInfo,
  activeNavName:state => state.base.activeNavName
}

export default getters
