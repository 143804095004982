/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-02 13:56:48
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-17 18:39:22
 * @FilePath: \ysz_web_international\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import * as axios from 'axios'
import Encode from './encode'
import { Message } from 'element-ui';
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // api的base_url
  timeout: 60000, // request timeout
  headers: {
    'Content-Type': 'application/json'
  },
  // 跨域是否带Token
  withCredentials: true,
  // 响应的数据格式 json / blob /document /arraybuffer / text / stream
  responseType: 'json',
  // XSRF 设置
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN'
})
// request interceptor
service.interceptors.request.use(config => {
  if (localStorage.getItem('ysz_web_international_token')) {
    config.headers.token = localStorage.getItem('ysz_web_international_token')
  }
  config.headers.guid = localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')).guid:window.uuid
  config.headers.uid = localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo')).uid:-1
  config.headers.platform = 'overseasPc' 
  // if (config.method === 'post') config.data = qs.stringify(config.data)
  // Do something before request is sent
  console.log(`%c 请求：${ config.url}`, 'color: blue;font-size: 16px',  config.data)
  if (config.method === 'post') {
    if (config.type) {
      config.headers['Content-Type'] = config.type
    } else {
      config.data = Encode.encodeFunc(config.data)
    }
  }
  // if (config.method === 'get') config.params = Secret.encrypt(config.params)
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  response => {
    var secretData = JSON.parse(Encode.decodeFuc(response.data.data))
    console.log(
      `%c 响应：${response.config.url
      }`,
      'color: green;font-size:16px;',
      secretData
  )
    if (secretData.code === 401) {
      // storage.remove('yxj_zlwx_token')
      localStorage.removeItem('ysz_web_international_token')
      Message.error('token验证失效！')
      window.location.href =  window.location.href.split('#')[0] +'#/login_register';
      // window.location.reload()
      return
    }
    return secretData
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          error.message = '错误请求'
          break
        case 401:
          error.message = '未授权，请重新登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = '请求错误,未找到该资源'
          break
        case 405:
          error.message = '请求方法未允许'
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器端出错'
          break
        case 501:
          error.message = '网络未实现'
          break
        case 502:
          error.message = '网络错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网络超时'
          break
        case 505:
          error.message = 'http版本不支持该请求'
          break
        default:
          error.message = `连接错误${error.response.status}`
      }
      Message.error(error.message)
    } else {
      error.message = '连接到服务器失败'
      Message.error(error.message)
    }
    return Promise.reject(error)
  })

export default {
  post (url, params, type) {
    console.log(url)
    return service({
      method: 'post',
      url,
      data: params?params:{},
      type
    })
  },
  get (url, data) {
    return service({
      method: 'get',
      url,
      params: data
    })
  }
}
