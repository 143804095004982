/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-05 16:36:38
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-11 17:32:48
 * @FilePath: \ysz_web_international\src\store\getters.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import base from './modules/base'

import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    base
  },
  state: {

  },
  mutations: {

  },
  actions: {},
  getters
})
// const Store = new Vuex.Store({
//   modules: {
//     user
//   },
//   getters
// })
// export default Store