<!--
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-01 10:24:28
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-08-05 16:25:25
 * @FilePath: \ysz_web_international\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <div class="content_box">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
  </div>
</template>

<script>
import DevicePixelRatio from './utils/devicePixelRatio.js';//放大缩小适配  

export default {
  name: 'App',
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
    this.setRem()
    window.addEventListener('resize', () => this.setRem());
    new DevicePixelRatio().init();//适配放大缩小
  },
  methods: {
    setRem () {
      const reScreenSize = (wid) => {
        // 当设备宽度小于1400px时，不在改变rem的值
        if (wid <= 1400) return;
        let w = document.documentElement.clientWidth; // 获取设备的宽度
        let n = 16 * (w / 1920)  +'px' 
        console.log(n, 16 * (w / 1920), 99999)
        document.documentElement.style.fontSize = n;
      };
      reScreenSize(document.documentElement.clientWidth);
    }
  },
  watch: {

  }
}
</script>

<style>
.ysz-ueditor-title-three-zsj::before {
  content: "";
  display: inline-block;
  width: 0.433333rem;
  height: 0.433333rem;
  background-color: #000;
  border-radius: 50%;
  margin: 0 0.3rem 0 0.6rem;
}
/* p:has(.ysz-ueditor-title-three-zsj) {
    margin: 0;
    padding: 0;
} */
body {
  padding: 0 !important;
}
html {
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  overflow-x: auto;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.scroll {
  position: relative;
}

.content_box {
  width: 100%;
  margin-top: 136px;
  min-height: calc(100vh - 368px);
  
}



</style>
