/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-05 13:55:06
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-15 13:37:44
 * @FilePath: \ysz_web_international\src\utils\message.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { Message } from 'element-ui';
let messageInstance = null;
const resetMessage = (options) => {
  console.log('resetMessage_options',options);
  options.duration = options.duration?options.duration:2000;
  if (messageInstance) {
    messageInstance.close();
  }
  options.showClose = true;
  messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach(type => {
  resetMessage[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      };
    }
    options.type = type;
    options.duration = 2000;
    options.customClass = 'custom-message';
    return resetMessage(options);
  };
});
export default resetMessage;
