/*
 $ @Author: hgc
 $ @Date: 2024-07-10 14:49:55
 $ @LastEditTime: 2024-07-10 14:53:13
 $ @LastEditors: hgc
 $ @Description: 
 $ @FilePath: \ysz_web_international\src\utils\common.js
 */
 import sa from 'sa-sdk-javascript';
// 将一个数组转换成单个子数组
export const transformArray = (arr = [], size = 1) => {
  return arr.length
    ? arr.reduce(
        (t, v) => (
          t[t.length - 1].length === size
            ? t.push([v])
            : t[t.length - 1].push(v),
          t
        ),
        [[]]
      )
    : []
}


/**
 * 防抖
 * @param {function} fn
 * @param {number} delay
 */
export function debounce (fn, delay = 500) {
  let timer = 0
  return function () {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, arguments)
      clearTimeout(timer)
    }, delay)
  }
}


// 数字格式化

export const numsFormat = (num) => {
  if (num < 10000) {
    return num;
  }
  const n = parseInt(num / 10000, 10);
  let other = num % 10000;
  other = parseInt(other / 1000, 10);
  if (other > 0) {
    return `${n}.${other}万`;
  }
  return n;
};

// 获取参数
export const getUrlParam = function (name) {
  const parmsStr = window.location.search.substring(1);
  const parmsArr = parmsStr.split('&');
  const parms = {};
  for (let i = 0; i < parmsArr.length; i++) {
    parms[parmsArr[i].split('=')[0]] = parmsArr[i].split('=')[1];
  }
  return parms[name];
};


/** 生成guid */
export const createUuid = function () {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  // localStorage.setItem("uuid", uuid)
  console.log(uuid);
  return uuid;
};

/**
 * 去除富文本标签
 * @param {*} value 富文本
 * @returns
 */
export function removeTag(value) {
  return value
    .replace(/<[^>]+>/g, '')
    .replace(/&nbsp;/g, '')
    .replace(/&ldquo;/g, '')
    .replace(/&rdquo;/g, '')
}

