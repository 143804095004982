/*
 * @Author: 汪永康 wangyongkang@yxj.org.cn
 * @Date: 2024-07-01 10:24:28
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-08-05 16:55:11
 * @FilePath: \ysz_web_international\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/element-ui.js'
import './utils/directive'
import 'element-ui/lib/theme-chalk/index.css';
// import './utils/clipboard.js'
import VueClipboard from 'vue-clipboard2';
import './utils/directive'
import './utils/platformTrans'
// 神策埋点 start
import sa from 'sa-sdk-javascript';
import storage from 'storejs';
import { createUuid } from './utils/common';

import './static/css/common.css'
import resetMessage from './utils/message';
Vue.config.productionTip = false
// router.afterEach(() => {
//   NProgress.done()
// })
sa.init({
  server_url: process.env.VUE_APP_SAURL,
  heatmap: {
    // 是否开启点击图，默认 default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭
    // 需要 JSSDK 版本号大于 1.7
    clickmap: 'default',
    // 是否开启触达注意力图，默认 default 表示开启，自动采集 $WebStay 事件，可以设置 'not_collect' 表示关闭
    // 需要 JSSDK 版本号大于 1.9.1
    scroll_notice_map: 'default',
    // scroll_notice_map: 'not_collect'
  },
  show_log: false,
  // 设置成 true 后， 表示在单页面开发的网站中，我们会对 hashchange 和 popstate 事件进行监听，当这两个事件任何一个发生时，也会发送 $pageview 事件。默认 false。
  is_single_page: true,
  // 设置成 true 后， 表示事件属性里面添加一个设备 id 的属性，存贮在 cookie 里面并发送。默认 false，记录但不发送。
  is_track_device_id: true,
});
console.log('sa', sa,process.env.VUE_APP_SAURL)
sa.quick('autoTrack')
// 判断uuid缓存是否存在，不存在则重新生成
let getUuid = storage('uuid');
if (!getUuid) {
  getUuid = createUuid();
  storage('uuid', getUuid);
}
window.uuid = getUuid;
Vue.use(VueClipboard)
Vue.prototype.$message = resetMessage;
new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
