/*
 * @Description:
 * @Version:
 * @Autor: yqf
 * @Date: 2021-11-02 18:23:52
 * @LastEditors: 汪永康 wangyongkang@yxj.org.cn
 * @LastEditTime: 2024-07-18 17:20:33
 * @FilePath: \yxj-mall-h5\src\router\index.js
 */
import Vue from "vue";
import Router from "vue-router";
import store from "../store";
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router);

function routers() {
  const Home = () => import("../views/index.vue");	// 首页
  const LoginRegister = () => import("../views/LoginRegister/index.vue");	// 登录注册
  const InputInformation = () => import("../views/LoginRegister/InputInformation.vue");	// 输入信息
  const welcome = () => import("../views/LoginRegister/welcome.vue");	// 欢迎页
  const forgotPassword = () => import("../views/LoginRegister/forgotPassword.vue");	// 忘记密码
  const resetPassword = () => import("../views/LoginRegister/resetPassword.vue");	// 重置密码
  const resetPasswordSuccess = () => import("../views/LoginRegister/resetPasswordSuccess.vue");	// 重置密码成功

  const router = [
    {
      path: "/",
      name: "Layouts",
      redirect: '/oriental_express',
      component: () => import("@/layouts/default"),
      children: [
        {
          path: "/oriental_express",
          name: "home",
          component: Home,
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/latestnews",
          name: "latestnews",
          component: ()=>import('../views/orientalExpress/latestnews.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/people",
          name: "people",
          component: ()=>import('../views/orientalExpress/people.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/clinicalPulse",
          name: "clinicalPulse",
          component: ()=>import('../views/clinicalPulse/index.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/clinicalPulse/list",
          name: "clinicalPulseList",
          component: ()=>import('../views/clinicalPulse/list.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/partnership",
          name: "partnership",
          component: ()=>import('../views/partnership/index.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/partnership/list",
          name: "partnershipList",
          component: ()=>import('../views/partnership/list.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/about",
          name: "about",
          component: ()=>import('../views/about/index.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/articleDetails",
          name: "articleDetails",
          component: ()=>import('../views/articleDetails/index.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/search",
          name: "Search",
          component: ()=>import('../views/search/index.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Oriental Express'
          }
        },
        {
          path: "/user_center",
          name: "userCenter",
          component: ()=>import('../views/userCenter/index.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'User Center'
          }
        },
        {
          path: "/privacy",
          name: "Privacy",
          component: ()=>import('../views/about/privacy.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Privacy Policy'
          }
        },
        {
          path: "/terms",
          name: "Terms",
          component: ()=>import('../views/about/terms.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'Terms of Use'
          }
        },
        {
          path: "/thirdParties",
          name: "thirdParties",
          component: ()=>import('../views/about/thirdParties.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'thirdParties'
          }
        },
        {
          path: "/privacy_us",
          name: "privacy_us",
          component: ()=>import('../views/about/privacy_Us.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'privacyUs'
          }
        },
        {
          path: "/privacy_europe",
          name: "privacy_europe",
          component: ()=>import('../views/about/privacy_europe.vue'),
          meta: {
            keepAlive: false,
            buriedTitle: 'privacyEurope'
          }
        },
      ]
    },
    {
      path: "/login_register",
      name: "LoginRegister",
      component: LoginRegister,
      meta: {
        keepAlive: false,
        buriedTitle: 'Login Register',
        module:'LoginRegister'
      }
    },
    {
      path: "/input_information",
      name: "InputInformation",
      component: InputInformation,
      meta: {
        keepAlive: false,
        buriedTitle: 'Input Information',
        module:'LoginRegister'
      }
    },
    {
      path: "/welcome",
      name: "welcome",
      component: welcome,
      meta: {
        keepAlive: false,
        buriedTitle: 'welcome',
        module:'LoginRegister'
      }
    },
    {
      path: "/forgot_password",
      name: "forgotPassword",
      component: forgotPassword,
      meta: {
        keepAlive: false,
        buriedTitle: 'Forgot Password',
        module:'LoginRegister'
      }
    },
    {
      path: "/reset_password",
      name: "resetPassword",
      component: resetPassword,
      meta: {
        keepAlive: false,
        buriedTitle: 'Reset Password',
        module:'LoginRegister'
      }
    },
    {
      path: "/reset_password_success",
      name: "resetPasswordSuccess",
      component: resetPasswordSuccess,
      meta: {
        keepAlive: false,
        buriedTitle: 'Reset Password',
        module:'LoginRegister'
      }
    },
	];
	return router;
}

// router对象工厂
function routerFactory() {
  // 加载路由
  const router = routers();
  const routerObj = new Router({ routes: router });
  // 全局router守卫
  routerObj.beforeEach(async (to, from, next) => {
    console.log('to',to)
    // const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    // if(screenWidth <= 768) {
    //   window.location.href = process.env.VUE_APP_MOBILE_ONLINE
    // }
    
    if (to.path==='/login_register'&&!from.meta.module&&from.meta.module!=='LoginRegister') {
      localStorage.setItem('oldPath',from.fullPath)
    }
    if (localStorage.getItem('ysz_web_international_token')) {
      store.dispatch('setIsLogin',true)
      store.dispatch('setUserInfo',JSON.parse(localStorage.getItem('userInfo')))
    }
    next()
  });

  return routerObj;
}



export default routerFactory();
